/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				var scrollElement = $('.header');
				var offset = scrollElement.height();
				$(window).scroll(function() {
						if ($(this).scrollTop() > 1) {
								scrollElement.addClass('sticky');
						} else {
								scrollElement.removeClass('sticky');
						}
				});

				// Form Warranty
                $(".contact__form_warranty .topic-brand select").change(function () {
                    $(".form_column-forms .liebherr").addClass("d-none");
                    $(".form_column-forms .falcon").addClass("d-none");
                    $(".form_column-forms .la-cornue").addClass("d-none");
                    $(".form_column-forms .liebherr-biomedical").addClass("d-none");
                    $(".form_column-forms .liebherr-commercial-equipment").addClass("d-none");

                    if ($(this).val() == 'liebherr'){
                        $(".form_column-forms .liebherr").removeClass("d-none");
                    }

                    if ($(this).val() == 'falcon'){
                        $(".form_column-forms .falcon").removeClass("d-none");
                    }

                    if ($(this).val() == 'la-cornue'){
                        $(".form_column-forms .la-cornue").removeClass("d-none");
                    }

                    if ($(this).val() == 'liebherr-biomedical'){
                        $(".form_column-forms .liebherr-biomedical").removeClass("d-none");
                    }

                    if ($(this).val() == 'liebherr-commercial-equipment'){
                        $(".form_column-forms .liebherr-commercial-equipment").removeClass("d-none");
                    }
                });


				// list icon trigger in single recipe
				if ($('.content-list').length) {
					$('.content-body ul li').click(function(){
						$(this).toggleClass('active');
					});
				}

	            // Homepage Banner Slider
	            if ($('.banner-slider').length) {
	                $('.banner-slider').slick({
	                    autoplay: true,
	                    autoplaySpeed: 5000,
	                    infinite: true,
	                    arrows: false,
	                    dots: true,
	                    fade: true,
	                    responsive: [
	                          {
	                            breakpoint: 993,
	                            settings: {
	                                dots: false,
	                            }
	                          },
	                          {
	                            breakpoint: 500,
	                            settings: {
	                                dots: false,
	                            }
	                          },
	                      ]
	                });
	            }


	            // Upsells Slider

	            jQuery(document).ready(function() {

                $('.wc-tab .upsells-slider .product__listing-cat').slick({
                  autoplay: true,
                  autoplaySpeed: 5000,
                  infinite: true,
                  arrows: true,
                  dots: true,
                  fade: false,
                  setPosition: 0,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  responsive: [
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true,
                      }
                    },
                    {
                      breakpoint: 500,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true,
                      }
                    },
                  ]
                });

                    // $('.wc-tab .product__features-functions-cat').slick({
                    //     autoplay: true,
                    //     autoplaySpeed: 5000,
                    //     infinite: true,
                    //     arrows: true,
                    //     dots: true,
                    //     fade: false,
                    //     setPosition: 0,
                    //     slidesToShow: 3,
                    //     slidesToScroll: 1,
                    //     responsive: [
                    //         {
                    //             breakpoint: 768,
                    //             settings: {
                    //                 slidesToShow: 2,
                    //                 slidesToScroll: 1,
                    //                 arrows: false,
                    //                 dots: true,
                    //             }
                    //         },
                    //         {
                    //             breakpoint: 500,
                    //             settings: {
                    //                 slidesToShow: 1,
                    //                 slidesToScroll: 1,
                    //                 arrows: false,
                    //                 dots: true,
                    //             }
                    //         },
                    //     ]
                    // });
	            });


           // $('.upsells-slider .product__listing-cat').slick('setPosition', 0);
              //
              $('#tab-title-accessories a').on('click', function(){
                $('button.slick-next.slick-arrow').click();
                var delayInMilliseconds = 500; //1 second

                setTimeout(function() {
                  $('.product__listing-cat').addClass('show');

                }, delayInMilliseconds);

              });

                // $('#tab-title-features_and_functions a').on('click', function(){
                //     $('button.slick-next.slick-arrow').click();
                //
                //     var delayInMilliseconds = 500; //1 second
                //
                //     setTimeout(function() {
                //         $('.product__features-functions-cat').addClass('show');
                //
                //     }, delayInMilliseconds);
                //
                // });

	            // mixitup masonry function - gallery page
			  	var initialFilter = 'all';
				var containerEl = document.querySelector('.gallery-filter');

			    if(containerEl) {
			    	var mixer = mixitup(containerEl, {
				    	load: {
				    		filter: initialFilter
				    	},

					 	animation: {
					      animateResizeContainer: false,
					      effects: 'fade scale'
					    }
				    });
			    }

			    $( '[data-fancybox="images"]' ).fancybox({
			    	infobar : false,
  					buttons : ["close"],
				    caption : function( instance, item ) {
				      return $(this).find('figcaption').html();
				  	},
				});

			    $( '[data-fancybox="video"]' ).fancybox({
			    	infobar : false,
  					buttons : ["close"],
				  	caption : function( instance, item ) {
				      	return $(this).find('figcaption').html();
				  	}
				});

				$( "#hamburger" ).click(function() {
	                $(".pop-up-menu").addClass('is-open');
              	});

                $( ".header__toggle#close" ).click(function() {
                    $(".pop-up-menu").removeClass('is-open');
                });

                $(".share__section").jsSocials({
						showLabel: false,
						showCount: false,
						shareIn: "popup",
						shares: ["facebook","twitter","pinterest"]
				});

                $('.product_details .product-thumbnails').slick({
                    dots: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    vertical: true,
					verticalSwiping: true,
                    arrows: true,

                    responsive: [
                        {
                            breakpoint: 992,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 1,
								arrows: false,
                                vertical: false,
                                verticalSwiping: false,
								dots: true,
							}
                        },
                        {
							breakpoint: 769,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 1,
								arrows: false,
								dots: true,
                                vertical: false,
                                verticalSwiping: false,
							}
						},
						{
							breakpoint: 500,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1,
								arrows: false,
								dots: true,
                                vertical: false,
                                verticalSwiping: false,
							}
						},
						{
							breakpoint: 400,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								arrows: false,
								dots: true,
                                vertical: false,
                                verticalSwiping: false,
							}
						}

                    ]
                });


				// product-thumbnails slider
			  	// if(jQuery('.product_details .product-thumbnails').length){
			 	// 	 var slick_settings = {
				//         autoplay: true,
				// 		autoplaySpeed: 5000,
				// 		infinite: true,
				// 		arrows: true,
				// 		dots: true,
				// 		fade: false,
				// 		 vertical: true,
				// 		 slidesToShow: 4,
				// 		 slidesToScroll: 4,
				// 		 verticalSwiping: true,
                //
				//         responsive: [
				//             // {
				//             //     breakpoint: 9999,
				//             //     settings: "unslick"
				//             // },
				//             {
				//                 breakpoint: 1200,
				//                 settings: {
				//                     slidesToShow: 4,
				//                     slidesToScroll: 1,
				//                     arrows: false,
				//                     dots: true,
				//                 }
				//             },
				//             {
				//                 breakpoint: 993,
				//                 settings: {
				//                     slidesToShow: 4,
				//                     slidesToScroll: 1,
				//                     arrows: false,
				//                     dots: true,
				//                 }
				//             },
				//             {
				//                 breakpoint: 992,
				//                 settings: {
				//                     slidesToShow: 3,
				//                     slidesToScroll: 1,
				//                     arrows: false,
				//                     dots: true,
				//                 }
				//             },
				//             {
				//                 breakpoint: 769,
				//                 settings: {
				//                     slidesToShow: 3,
				//                     slidesToScroll: 1,
				//                     arrows: false,
				//                     dots: true,
				//                 }
				//             },
				//             {
				//                 breakpoint: 500,
				//                 settings: {
				//                     slidesToShow: 2,
				//                     slidesToScroll: 1,
				//                     arrows: false,
				//                     dots: true,
				//                 }
				//             },
				//             {
				//                 breakpoint: 400,
				//                 settings: {
				//                     slidesToShow: 1,
				//                     slidesToScroll: 1,
				//                     arrows: false,
				//                     dots: true,
				//                 }
				//             }
				//         ]
				// 	};
                //
				// 	//Gallery Slick
				//    if( jQuery(window)[0].innerWidth > 987 ) {
				//        if (jQuery('.product-thumbnails').hasClass('slick-initialized')) {
				//              jQuery('.product-thumbnails').slick("unslick");
				//        }
				//    } else {
				//        if (jQuery('.product-thumbnails').length) {
				//            jQuery('.product-thumbnails').slick(slick_settings);
				//        }
				//    }
                //
				//    jQuery(window).resize(function() {
				//        var window_size = jQuery(window)[0].innerWidth;
				//        var obj = jQuery('.product-thumbnails');
				//        if (window_size > 987) {
				//            if ( obj.hasClass('slick-initialized') ) {
				//                obj.slick("unslick");
				//            }
				//        } else {
				//            if ( obj.hasClass('slick-initialized') ) {
				//            } else {
				//                obj.slick(slick_settings);
				//            }
				//        }
				//    });
			 	// }

			 	// sidebar
			 	if($('.sidebar__main').length) {
			 		$('.sidebar__main .toggle-collapse').on('click', function(){
			 			$(this).toggleClass('active');
			 			$('.sidebar__main .collapse-element').slideToggle();
			 			if ($(this).hasClass('active')) {
							$(this).find('i.icon')
								.removeClass('icon-plus')
								.addClass('icon-minus');
						} else {
							$(this).find('i.icon')
								.removeClass('icon-minus')
								.addClass('icon-plus');
						}
			 		});
				 }

				$(document).ready(function() {
					//Preloader
					$(window).on("load", function() {
						preloaderFadeOutTime = 500;
						function hidePreloader() {
							var preloader = $('.spinner-wrapper');
							preloader.fadeOut(preloaderFadeOutTime);
						}
						hidePreloader();
					});
				});

				// for brochures flipbook animation
				$('.js-trigger-flipbook').click(function(e) {
					e.preventDefault();
					$(this).parents('.js-brochure-item').siblings('a[data-lity]').click();
				});

				// set default style as hidden for brochure (trigger with button instead)
				$('.js-brochure-item').siblings('a[data-lity]').css({
					'display': 'block',
				    'height': 0,
				    'opacity': 0,
				    'position': 'absolute',
				    'top': 0,
				    'left': 0
				});

				$('.js-brochure-item').siblings('a[data-lity]').find('img').hide();

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'compare': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};
	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
